import { type AnalyticsBrowser } from '@segment/analytics-next';
import { useLatest } from 'react-use';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useFetchGameSessionGamePack } from '../Game/hooks/gameSessionHooks';
import { usePlaybackInfoCurrentBlockBrand } from '../Game/Playback/PlaybackInfoProvider';
import { useMyOrgId } from '../Organization';
import { useStreamSessionId } from '../Session';
import { useVenueId } from '../Venue';

type Props = {
  venueId: string;
  sessionId: string | null;
  orgId: string | null;
  gamePackId: string | null;
  gamePackName: string | null;
  brandId: string | null;
  brandName: string | null;
};

export class ClosedCaptionsAnalytics {
  constructor(readonly analytics: AnalyticsBrowser) {}

  trackClosedCaptionsClicked(enabled: boolean, extra: Props) {
    this.analytics.track('Closed Captions Clicked', {
      closedCaptionsEnabled: enabled,
      ...extra,
    });
  }

  trackClosedCaptionsLanguageClicked(
    code: string,
    label: string,
    extra: Props
  ) {
    this.analytics.track('Closed Captions Language Clicked', {
      closedCaptionsCode: code,
      closedCaptionsLabel: label,
      ...extra,
    });
  }

  trackClosedCaptionsSettingsClicked(extra: Props) {
    this.analytics.track('Closed Captions Settings Clicked', {
      ...extra,
    });
  }

  trackVoiceOverLanguageClicked(code: string, label: string, extra: Props) {
    this.analytics.track('Voice Over Language Clicked', {
      voiceOverCode: code,
      voiceOverLabel: label,
      ...extra,
    });
  }
}

export function useGetSharedTrackingProps() {
  const brand = usePlaybackInfoCurrentBlockBrand();
  const gp = useFetchGameSessionGamePack();

  const trackingProps = useLatest({
    orgId: useMyOrgId(),
    venueId: useVenueId(),
    sessionId: useStreamSessionId() ?? null,
    gamePackId: gp?.id ?? null,
    gamePackName: gp?.name ?? null,
    brandId: brand?.id ?? null,
    brandName: brand?.name ?? null,
  });

  return useLiveCallback((): Props => trackingProps.current);
}
